import { faArrowRight, faPlantWilt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Divider, Stack, Typography } from "@mui/joy"
import CardTitleZone from "../../../components/CardTitleZone"
import InfoText from "../../../components/InfoText"
import { orange } from "@mui/material/colors"

const PertesPostRecoltesZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
                maxHeight: 400
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardTitleZone
                title="Réduction des pertes post-récoltes."
                icon={faPlantWilt}
            />

            <InfoText
                text="Les stratégies de réduction des pertes après récoltes des filières cibles du projet."
            />

            <Stack
                gap={1}
                sx={{
                    overflowY: "scroll"
                }}
            // sx={{ "& > *": { color: orange[900] } }}
            >
                <Typography>
                    Les pertes sont les plus significatives à certaines étapes critiques telles que la pré-récolte, la récolte, la transformation, le stockage et la commercialisation.
                </Typography>
                <Typography>
                    La réduction des pertes à la récolte et la transformation bénéficieraient grandement d’actions ciblées telles que l’amélioration de l’accès aux intrants de qualité, le renforcement des capacités techniques et organisationnelles des acteurs, l’utilisation d’équipements agricoles et de transformation adaptés, etc.
                </Typography>
                <Typography>
                    Pour réduire durablement les pertes au niveau de la commercialisation, il faut mener des actions sur le transport, le stockage et les conditions d’exposition à la vente .
                    (FAO, Étude diagnostique de la reduction des pertes, 2018).
                </Typography>
            </Stack>

            <Button
                endDecorator={
                    <FontAwesomeIcon icon={faArrowRight} />
                }
                sx={{
                    alignSelf: "flex-end",
                    borderRadius: `15px 0`
                }}
            >Voir plus</Button>
        </Card>
    )
}

export default PertesPostRecoltesZone