import { Card, Stack } from "@mui/joy"
import CardTitleZone from "../../../../components/CardTitleZone"
import Item from "./Item"
import data from "./data"

const CommercialisationZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardTitleZone title="Commercialisation" />

            <Stack
                gap={1}
            >
                {
                    data.map((value, index) => (
                        <Item
                            title={value.title}
                            elementListe={value.elemetListe}
                            key={index}
                        />
                    ))
                }
            </Stack>
        </Card>
    )
}

export default CommercialisationZone