import { faCalendar, faCalendarCheck, faInfoCircle, faWheatAwn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Card, CardContent, Option, Select, Stack, Typography } from '@mui/joy'
import { green, orange } from '@mui/material/colors'
import PrixZone from './PrixZone'

const PrixDuMarcheZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardContent>
                <Typography fontWeight={700} textColor={green[900]} level='body-lg' component={"h1"}>Prix du marché</Typography>
                <Box
                    bgcolor={orange[500]}
                    height={10}
                    width={70}
                    borderRadius={30}
                />
            </CardContent>

            <CardContent>
                <Typography>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                    Rétrouver le prix de vente à la consommation hebdomadaire. Exprimé en FCFA par Killogramme.
                </Typography>
            </CardContent>

            <CardContent>
                <Stack>
                    <Stack
                        direction={"row"}
                        spacing={1}
                    >
                        <Avatar sx={{ bgcolor: orange[500] }} variant='solid' size='sm'>
                            <FontAwesomeIcon icon={faCalendar} />
                        </Avatar>
                        <Stack
                            bgcolor={orange[500]}
                            borderRadius={50}
                            color="white"
                            px={2}
                            justifyContent={"center"}
                            textAlign={"center"}
                            fontWeight={700}
                        >27/01/2025</Stack>
                    </Stack>

                    <Box
                        height={30}
                        width={5}
                        bgcolor={orange[500]}
                        borderRadius={50}
                        ml={'13px'}
                        my={-1}
                    />

                    <Stack
                        direction={"row"}
                        spacing={1}
                    >
                        <Avatar sx={{ bgcolor: orange[500] }} variant='solid' size='sm'>
                            <FontAwesomeIcon icon={faCalendarCheck} />
                        </Avatar>
                        <Stack
                            bgcolor={orange[500]}
                            borderRadius={50}
                            color="white"
                            px={2}
                            justifyContent={"center"}
                            textAlign={"center"}
                            fontWeight={700}
                        >27/01/2025</Stack>
                    </Stack>
                </Stack>
            </CardContent>
            <CardContent>
                <Select
                    defaultValue={"Manioc"}
                    value={"Manioc"}
                    startDecorator={<FontAwesomeIcon icon={faWheatAwn} />}
                >
                    <Option value={"Manioc"}>Manioc</Option>
                    <Option value={"Maïs"}>Maïs</Option>
                    <Option value={"Riz"}>Riz</Option>
                    <Option value={"Blé"}>Blé</Option>
                </Select>
            </CardContent>

            <CardContent>
                <PrixZone />
            </CardContent>
        </Card>
    )
}

export default PrixDuMarcheZone