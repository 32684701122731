import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PAGE_PAGE } from '../constant';
import Accueil from '../pages/Accueil';

const Router = () => {

    return (
        <BrowserRouter >
            <Routes>
                <Route path='/*' Component={Accueil} />
                {
                    PAGE_PAGE.map((value, index) => (
                        <Route path={value.path} Component={value.component} key={index} />
                    ))
                }
                
            </Routes>


        </BrowserRouter>
    )
}

export default Router