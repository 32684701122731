export default [
    {
        title: "System de reproduction",
        elemetListe: [
            "La riziculture irriguée (de bas-fonds)",
            "la riziculture inondée (de plaine)",
            "la riziculture pluviale (de plateau)"
        ]
    },
    {
        title: "Techniques / Technologles",
        elemetListe: [
            "Irrigation Contrôlée",
            "Fertilisation équilibrée",
            "Systeme de Culture Intensive",
            " Mécanisation agricole / Motorisation"
        ]
    },
]