import gombos from "../../../assets/images/gombos.jpeg"
import hommeAvecPaye from "../../../assets/images/homme-avec-paye.jpeg"
import mais from "../../../assets/images/mais.jpeg"
import manioc from "../../../assets/images/manioc.jpeg"

export default [
    {
        title: "Projet de transformation de Riz",
        img: hommeAvecPaye
    },
    {
        title: "Projet de transformation du maïs",
        img: mais
    },
    {
        title: "Projet de transformation du manioc",
        img: manioc
    },
    {
        title: "Projet de transformation du gombos",
        img: gombos
    },
]