import { Card, Divider, Stack, Typography } from "@mui/joy"
import { CardMedia } from "@mui/material"
import { IMAGES } from '../constant'
import { green, orange } from "@mui/material/colors"

const Header = () => {
    return (
        <Stack
            py={2}
            px={"5%"}
            direction={"row"}
            justifyContent={"space-between"}
        >
            <CardMedia
                component="img"
                src={IMAGES.logo}
                sx={{
                    height: 50,
                    width: "auto"
                }}
            />

            {
                window.innerWidth > 800 && (
                    <Stack
                        alignItems={"center"}
                    >
                        <Typography level="body-sm" textColor={green[800]}>
                            PROJET DE PÔLE AGRO-INDUSTRIEL DANS LA REGION DU BELIER ET DU DISTRICT AUTONOME DE YAMOUSSOUKRO
                        </Typography>
                        <Divider
                            sx={{
                                width: '70%',
                                alignSelf: "center",
                                height: 5,
                                borderRadius: 30,
                                bgcolor: orange[400]
                            }}
                        />
                        <Typography level="h3" textColor={green[800]} >DATA</Typography>
                    </Stack>
                )
            }


            <CardMedia
                component="img"
                src={IMAGES.armobanko}
                sx={{
                    height: 50,
                    width: "auto"
                }}
            />
        </Stack>
    )
}

export default Header