export default [
    {
        name: "Intrants / Semences.",
    },
    {
        name: "Services Mécanisés",
    },
    {
        name: "Engrais / Produits phytosanitaires",
    },
    {
        name: "Mise en place / Prix",
    },
]