import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stack, Typography } from '@mui/joy'

const PrixItem = ({
    localite = "Abidjan",
    value = 1800
}) => {
    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            borderBottom={`1px solid`}
            mt={1}
        >
            <Typography>
                <FontAwesomeIcon
                    icon={faDotCircle}
                    style={{ marginRight: 10 }}
                />
                {localite}
            </Typography>
            <Typography>{value} <sup>F/Kg</sup></Typography>
        </Stack>
    )
}

export default PrixItem