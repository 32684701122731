import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Card, CardContent, CardCover, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'

const MicroProjetItem = ({ image, text, link }: { image: string, text: string, link: string }) => {
    return (
        <Card
            sx={{
                p: 0,
                borderRadius: 10,
                overflow: "hidden",
                height: 190,
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <CardCover>
                <CardMedia
                    component={"img"}
                    src={image}
                    sx={{
                    }}
                />
            </CardCover>
            <CardCover
                sx={{
                    bgcolor: "black",
                    opacity: 0.5
                }}
            />
            <CardContent
                sx={{
                    height: '100%',
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Typography level='h3' textColor={"white"}>
                    {text}
                </Typography>
                <Avatar>
                    <FontAwesomeIcon icon={faDownload} />

                </Avatar>
            </CardContent>

        </Card>
    )
}

export default MicroProjetItem