import { faAngleDown, faAngleUp, faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Typography } from '@mui/joy'
import InfoText from '../../../../components/InfoText'
import { Collapse } from '@mui/material'
import { useState } from 'react'
import { orange } from '@mui/material/colors'

const Item = ({ title, elementListe }: { title: string, elementListe?: string[] }) => {
    const [isOpened, setisOpened] = useState(true);

    return (
        <Stack
            gap={2}
        >
            <Stack
                direction={"row"}
                gap={2}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <InfoText
                    text={title}
                    sx={{
                        fontSize: 16,
                        fontWeight: 700,
                        color: orange[800]
                    }}
                />
                <Button
                    variant='plain'
                    color='warning'
                    onClick={() => setisOpened(!isOpened)}
                >
                    <FontAwesomeIcon
                        icon={isOpened ? faAngleDown : faAngleUp}
                    />
                </Button>

            </Stack>

            <Collapse
                in={isOpened}
                sx={{
                    ml: 1,
                    px: 1,
                    borderLeft: `5px solid ${orange[700]}`,
                }}
            >
                {
                    elementListe?.map((element, index) => (
                        <Typography
                            key={index}
                            sx={{
                                pb: 1,
                                borderBottom: `1px dashed`
                            }}
                        >{element}</Typography>
                    ))
                }
            </Collapse>
        </Stack>
    )
}

export default Item