import Accueil from "../pages/Accueil";
import FinancementSoutien from "../pages/FinancementSoutien";
import TechniquesTechnologies from "../pages/TechniquesTechnologies";

export default [
    {
        path: "accueil",
        component: Accueil
    },
    {
        path: "techniques-technologies",
        component: TechniquesTechnologies
    },
    {
        path: "financement-soutien",
        component: FinancementSoutien
    },
]