import { Card, Divider, Link, Stack, Typography } from '@mui/joy'
import { orange } from '@mui/material/colors'

const Footer = () => {
    return (
        <Card
            sx={{
                flexDirection: "row",
                gap: 2,
                background: orange[600],
                borderRadius: 0,
                p: 1,
                flexWrap: "wrap",
            }}
        >
            <Typography>
                Copyright © 2024 PProjet de Pôle Agro-Industriel dans la région du Bélier (2PAI-BELIER). Tous droits réservés.
            </Typography>
            <Divider orientation='vertical' />
            <Link>
                Conditions générales
            </Link>
            <Divider orientation='vertical' />
            <Link>
                Alerte à la fraude
            </Link>
        </Card>
    )
}

export default Footer