import { faCaretDown, faComputer, faDashboard, faMoneyCheck } from "@fortawesome/free-solid-svg-icons"

export default [
    {
        path: "/",
        text: "Tableau de bord",
        icon: faDashboard
    },
    {
        path: "techniques-technologies",
        text: "Techniques & Technologies",
        icon: faComputer
    },
    {
        path: "financement-soutien",
        text: "Financement & Soutiens",
        icon: faMoneyCheck
    },
    {
        path: "/",
        text: "Réduction des pertes post-récoltes",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Réduction des pertes post-récoltes",
        icon: faCaretDown
    },
    {
        path: "https://data2paibelier.it.rehum.site/bonnes-pratiques/",
        text: "Bonnes pratiques",
        icon: faCaretDown,
        newSpace : true
    },
    {
        path: "/",
        text: "Modèles – plan affaires & financement",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Fiches micro-projets potentiels",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Gestion environnementale et sociale",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Réglementation Cadre juridique",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Equipe AT SOFRECO",
        icon: faCaretDown
    },
    {
        path: "/",
        text: "Banque de fichiers media",
        icon: faCaretDown
    },

]