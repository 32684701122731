export default [
    {
        title: "Relation Directe Grossistes",
        elemetListe: [
            ` Producteurs
            via une relation contractuelle ou informelle
            de préfinancement de la campagne en
            ntrants et autres facteurs de production en
            contrepartie du paddty de valeur équivalente`
        ]
    },
    {
        title: "Relation Grossistes",
        elemetListe: [
            `Détaillants via des
            dépots ventes ou souvent basés sur les
            paierents comptant sans engagement`
        ]
    },
    {
        title: "Vente directe aux pisteurs",
        elemetListe: [
            `va un
            paiement au comptant et la présence du
            pisteur au village ou sur le site de production`
        ]
    },
]