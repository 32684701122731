import { motion } from "framer-motion"

const AppearOnView = (
    {
        element,
        x = 0,
        y = 80,
    }: {
        element: any,
        x?: number,
        y?: number,
    }
) => {
    return (
        <motion.div
            initial={{
                y: y,
                x: x,
                opacity: 0
            }}
            whileInView={{
                y: 0,
                x: 0,
                opacity: 1,
                transition: {
                    duration: 0.8,
                    delay: 0.5
                }
            }}
            style={{
                borderRadius: 20,
            }}
        >
            {element}
        </motion.div>
    )
}

export default AppearOnView