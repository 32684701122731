export default [
    {
        name: "Répertoire des bénéficiaires",
    },
    {
        name: "Production et Rendement",
    },
    {
        name: "Intrants et Équipements",
    },
    {
        name: "Informations sur les marchés",
    },
]