import logo from "../assets/images/logo.png"
import armobanko from "../assets/images/armobanko.png"
import repertoireAcheteurs from "../assets/images/repertoireAcheteurs.jpeg"
import FluxCommercialisationZone from "../assets/images/FluxCommercialisationZone.jpg"
import background from "../assets/images/background.jpeg"

export default {
    logo,
    armobanko,
    repertoireAcheteurs,
    FluxCommercialisationZone,
    background,
}