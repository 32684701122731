import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import { EffectFade, Autoplay, Navigation, Thumbs, Parallax, Pagination } from 'swiper/modules';
import data from "./data";
import MicroProjetItem from './MicroProjetItem';

const SwipperZone = () => {
    return (
        <Swiper
            slidesPerView={window.innerWidth < 600 ? 1 : 3}
            modules={[EffectFade, Autoplay, Navigation, Thumbs, Parallax, Pagination]}
            effect='fade'
            autoplay={{
                delay: 5000
            }}
            loop
            style={{
                width: "100%",
            }}
            pagination
        >
            {
                data.map((value, index) => (
                    <SwiperSlide
                        key={index}
                    >
                        <MicroProjetItem
                            image={value.img}
                            text={value.title}
                            link={value.title}
                        />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}

export default SwipperZone