export default [
    {
        title: "Par les unités artisanales",
        elemetListe: [
            `Capacités comprises entre 200 kg/h et 1 t/h.`
        ]
    },
    {
        title: "Par les mini-rizeries ",
        elemetListe: [
            `Capacités comprises entre 1t/h et 2 t/h`
        ]
    },
    {
        title: "ses unités industrielles",
        elemetListe: [
            `Capacités minimales de 5 t/h`
        ]
    },
]