export default [
    {
        title: "Assistance Technique SOFRECO",
        content: "Le cabinet d’Assistance Technique assure la formation et le suivi des bénéficiaires à travers l’appui conseil, la mise à niveau et le coaching, afin de renforcer le développement de l’entreprise et faciliter le recouvrement du prêt."
    },
    {
        title: "Centre Mise à Niveau et de Conformité (CMNC)",
        content: "L’objectif du Centre Mise à Niveau et de Conformité (CMNC) est d’accompagner les entreprises, organiser les producteurs et porteurs de projets dans un processus d’apprentissage susceptible d’induire des changements qualitatifs dans le développement de leurs affaires."
    },
    {
        title: "Côte d’Ivoire PME (CI PME)",
        content: "L’Agence   CI PME a pour mission de faire la promotion des PME ivoiriennes et de contribuer à la mise en œuvre opérationnelle de la stratégie de développement des PME."
    },
    {
        title: "Office d’aide à la Commercialisation des Produits Vivriers (OCPV)",
        content: "L’OCPV, pour vous aider à mieux vendre vos produits. L’OCPV a pour mission de moderniser la commercialisation des produits vivriers, organiser les marchés de produits vivriers, améliorer l’approvisionnement et la distribution, assistance aux opérateurs valorisation et promotion des produits vivriers."
    },
]