import { Card, CardContent, CardCover, Grid, Stack } from "@mui/joy"
import BibliothequeDeDonneesZone from "./BibliothequeDeDonneesZone"
import CartographieZone from "./CartographieZone"
import StatistiquesZone from "./StatistiquesZone"
import PrixDuMarcheZone from "./PrixDuMarcheZone"
import RepertoireAcheteursZone from "./RepertoireAcheteursZone"
import FluxCommercialisationZone from "./FluxCommercialisationZone"
import BonnesPratiqueZone from "./BonnesPratiqueZone"
import { green } from "@mui/material/colors"
import IntrantsEquipements from "./IntrantsEquipements"
import MicroProjetsZone from "./MicroProjetsZone"
import PlanAffairesZone from "./PlanAffairesZone"
import EncadrementPromoteursZone from "./EncadrementPromoteursZone"
import EnvironnementSocialZone from "./EnvironnementSocialZone"
import PertesPostRecoltesZone from "./PertesPostRecoltesZone"
import { IMAGES } from "../../constant"
import { motion } from "framer-motion"
import AppearOnView from "../../components/AppearOnView"

const Accueil = () => {
    return (
        <Stack>
            <Grid
                container
                mx={window.innerWidth < 1500 ? 2 : "10rem"}
                spacing={2}
                mt={5}
            >
                <Grid
                    xs={12}
                    sm={3}
                    md={3}
                >
                    <Stack
                        gap={2}
                        sx={{
                            ">div": {
                                boxShadow: `0px 5px 10px rgba(44, 77, 18, 0.28)`
                            }
                        }}
                    >
                        <AppearOnView
                            element={<BibliothequeDeDonneesZone />}
                        />
                        <AppearOnView
                            element={<CartographieZone />}
                        />
                    </Stack>
                </Grid>

                <Grid
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                        "&::-webkit-scrollbar": {
                            display: "none"
                        }
                    }}
                >
                    <AppearOnView
                        element={<StatistiquesZone />}
                    />

                    <Stack>
                        <Grid
                            container
                            spacing={2}
                            mt={2}
                        >
                            <Grid
                                xs={12}
                                sm={6}
                            >
                                <AppearOnView
                                    element={<RepertoireAcheteursZone />}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                sm={6}
                            >
                                <AppearOnView
                                    element={<FluxCommercialisationZone />}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>

                <Grid
                    xs={12}
                    sm={3}
                    md={3}
                >
                    <Stack
                        gap={2}
                    >
                        <AppearOnView
                            element={<PrixDuMarcheZone />}
                        />
                        <AppearOnView
                            element={<BonnesPratiqueZone />}
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Grid
                container
                sx={{
                    bgcolor: green[200],
                    mt: 8,
                }}
                px={window.innerWidth < 1500 ? 2 : "10rem"}
                py={5}
                gap={2}
                wrap={window.innerWidth < 1300 ? "wrap" : "nowrap"}
            >
                <Grid
                    xs={12}
                    sm={3}
                    md={3}
                >
                    <AppearOnView
                        element={<IntrantsEquipements />}
                        x={-100}
                        y={0}
                    />
                </Grid>

                <Grid
                    xs={12}
                    sm={6}
                    md={6}
                >
                    <AppearOnView
                        element={<MicroProjetsZone />}
                    />
                </Grid>

                <Grid
                    xs={12}
                    sm={3}
                    md={3}
                >
                    <AppearOnView
                        element={<PlanAffairesZone />}
                        x={100}
                        y={0}
                    />
                </Grid>

            </Grid>

            <Card
                sx={{
                    p: 0,
                    px: 1,
                    m: 0,
                    borderRadius: 0,
                }}
            >
                <CardCover >
                    <img src={IMAGES.background} />
                </CardCover>
                <CardContent>
                    <Grid
                        container
                        px={window.innerWidth < 1500 ? 2 : "10rem"}
                        py={5}
                        spacing={2}
                    >
                        <Grid
                            xs={12}
                            sm={4}
                            md={4}
                        >
                            <AppearOnView
                                element={<EncadrementPromoteursZone />}
                                x={-100}
                                y={0}
                            />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={4}
                            md={4}
                        >
                            <AppearOnView
                                element={<EnvironnementSocialZone />}
                            />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={4}
                            md={4}
                        >
                            <AppearOnView
                                element={<PertesPostRecoltesZone />}
                                x={100}
                                y={0}
                            />
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    )
}

export default Accueil