import { Box, Card, CardContent, Typography } from '@mui/joy'
import { green, orange } from '@mui/material/colors'
import React from 'react'
import SeparatorMin from '../../../components/SeparatorMin'
import InfoText from '../../../components/InfoText'
import SwipperZone from './SwipperZone'

const MicroProjetsZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardContent>
                <Typography
                    fontWeight={700}
                    textColor={green[900]}
                    level='body-lg'
                    component={"h1"}
                >
                    Fiches de Micro-projets potentiels viables
                </Typography>
                <SeparatorMin />
            </CardContent>

            <CardContent>
                <InfoText
                    text={`Cliquez sur un élement de la liste pour télecharger un pdf d'informations`}
                />
            </CardContent>

            <CardContent>
                <SwipperZone />
            </CardContent>
        </Card>
    )
}

export default MicroProjetsZone