import { faArrowRight, faBookAtlas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Divider, Stack, Typography } from "@mui/joy"
import { green } from "@mui/material/colors"
import { default as DATA_LIST } from "./data"
import { Link } from "react-router-dom"

const BibliothequeDeDonneesZone = () => {
    return (

        <Card
            sx={{
                border: `1px solid`,
                borderRadius: 20,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <Stack
                sx={{
                    p: 1,
                    bgcolor: green[100],
                    borderRadius: `10px 10px 0 0`,
                }}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Typography fontWeight={700} textColor={green[900]} component={"h1"}>Bibliothèque de données</Typography>
                <FontAwesomeIcon icon={faBookAtlas} />
            </Stack>

            <Stack
                gap={1}
            >
                {
                    DATA_LIST.map((value, index) => (
                        <>
                            <Link
                                key={index}
                                to={`/bibliotheque-de-donnees/${value.name}`}
                                style={{
                                    color: "black"
                                }}
                            >
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                >
                                    <Typography>{value.name}</Typography>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Stack>
                            </Link>
                            {
                                index !== DATA_LIST.length - 1 && (
                                    <Divider />
                                )
                            }
                        </>
                    ))
                }
            </Stack>
        </Card>
    )
}

export default BibliothequeDeDonneesZone