export default [
    {
        locaite: "Abidjan",
        value: 310,
    },
    {
        locaite: "Bouaké",
        value: 430,
    },
    {
        locaite: "Korhogo",
        value: 260,
    },
    {
        locaite: "Man",
        value: 130,
    },
    {
        locaite: "San Pédro",
        value: 180,
    },
]