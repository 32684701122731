import { default as prixList } from "../data"
import PrixItem from './PrixItem'

const PrixZone = () => {
    return (
        /*
            //@ts-ignore */
        < marquee
            behavior={"scroll"}
            direction={"up"}
            scrollamount={"2"}
            style={{
                maxHeight: 100
            }}
        >
            {
                prixList.map((value, index) => (
                    <PrixItem
                        key={index}
                        localite={value.locaite}
                        value={value.value.toLocaleString() as any}
                    />
                ))
            }

            {/* 
            //@ts-ignore */}
        </marquee >
    )
}

export default PrixZone