import { Card, CardContent, Stack, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'

const BonnesPratiqueItem = (
    {
        name,
        description,
        image
    }: {
        name: string,
        description: string,
        image: any
    }
) => {
    console.log('====================================');
    console.log(image);
    console.log('====================================');
    return (
        <Card
            sx={{
                borderRadius: 25,
                border: 0,
                p: 0,
                transition: `all .2s`,
                overflow: "hidden",
                background: `linear-gradient(90deg, #9ebd13 0%, #008552 100%)`,
            }}
            component="section"
        >
            <CardContent>

                <CardMedia
                    component="img"
                    src={image}
                    sx={{
                        width: "100%",
                        maxHeight: 150,
                    }}
                />

                <Stack
                    p={1}
                    color={"white"}
                    gap={2}
                >
                    <Typography component={"h1"} textColor={"white"} level='title-lg'>{name}</Typography>
                    <Typography textColor={"white"} level='body-md'>{description}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default BonnesPratiqueItem