export default [
    {
        name: "Cartographie des acteurs",
    },
    {
        name: "Cartographie des risques",
    },
    {
        name: "Zone industrielle",
    },
    {
        name: "Sites amenagés",
    },
]