import { faDownload, faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Divider, Stack, Typography } from "@mui/joy"
import CardTitleZone from "../../../components/CardTitleZone"
import InfoText from "../../../components/InfoText"

const PlanAffairesZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardTitleZone
                title="Modèles – plan affaires & financement"
                icon={faFilePdf}
            />

            <InfoText
                text="Fiches d’élaboration de plan d’affaires et de demande de financement."
            />

            <Divider />

            <Stack
                gap={1}
            >
                <Button
                    startDecorator={
                        < FontAwesomeIcon icon={faDownload} />
                    }
                >
                    <Typography textColor={"white"} width={"100%"} textAlign={"left"}>Modèle de demande de financement</Typography>
                </Button>
                <Button
                    startDecorator={
                        < FontAwesomeIcon icon={faDownload} />
                    }
                >
                    <Typography textColor={"white"} width={"100%"} textAlign={"left"}>
                        Des plans d’affaires prêts à l'emploi
                    </Typography>

                </Button>
            </Stack>
        </Card >
    )
}

export default PlanAffairesZone