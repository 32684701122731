import { faArrowRight, faPlantWilt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Card, Divider, Stack, Typography } from "@mui/joy"
import CardTitleZone from "../../../components/CardTitleZone"
import InfoText from "../../../components/InfoText"
import { orange } from "@mui/material/colors"

const EnvironnementSocialZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
                maxHeight: 400
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardTitleZone
                title="Gestion environnementale et sociale"
                icon={faPlantWilt}
            />

            <InfoText
                text="La mise en œuvre des trois (03) composantes du Projet  2PAI-Belier a plusieurs impacts positifs  sur le plan environnemental et social dans la zone du Projet. Des mésures d’atténuation sont prises pour les activités susceptibles d’avoir des impacts négatifs.Le bouton Exigences Environnementales presente quelques exigences liées à l’environnement qu’il faut respecter."
            />

            <Stack
                gap={1}
                sx={{
                    "& > *": {
                        color: orange[900]
                    },
                    overflowY:"scroll"
                }}
            >
                <Typography>
                    Impacts Positifs du Projet
                </Typography>
                <Divider />

                <Typography>
                    Impacts Négatifs Potentiels
                </Typography>
                <Divider />

                <Typography>
                    Mésures d’atténuation des impacts
                </Typography>
                <Divider />

                <Typography>
                    Exigences Environnementales
                </Typography>
            </Stack>

            <Button
                endDecorator={
                    <FontAwesomeIcon icon={faArrowRight} />
                }
                sx={{
                    alignSelf: "flex-end",
                    borderRadius: `15px 0`
                }}
            >Voir plus</Button>
        </Card>
    )
}

export default EnvironnementSocialZone