import { Button, Card, Stack } from "@mui/joy"
import { HEADER_LINKS } from "../constant"

const PlainNavbar = () => {
    return (
        <Card
            sx={{
                borderRadius: 0,
                border: 0,
                background: `linear-gradient(90deg,rgb(154, 189, 0) 0%, #008552 100%)`,
            }}
        >
            <Stack
                justifyContent="flex-start"
                direction="row"
                gap={1}
                sx={{
                    overflowX: "scroll",
                    scrollbarWidth: "none",
                }}
                mx={-2}
                px={2}

            >
                {
                    HEADER_LINKS.map((value, index) => (
                        <Button
                            component="a"
                            href={value.path}
                            key={index}
                            variant="soft"
                            sx={{
                                textWrap: "nowrap"
                            }}
                            color="success"
                            target={value?.newSpace ? "_blank" : undefined}
                        >
                            {value.text}
                        </Button>
                    ))
                }
            </Stack>


        </Card>
    )
}

export default PlainNavbar