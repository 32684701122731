import { faHashtag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardContent, Grid, Typography } from '@mui/joy'
import { useEffect, useState } from 'react';

const StatistiqueItem = ({
    bgcolor = `linear-gradient(90deg, #d53369 0%, #daae51 100%)`,
    value = 73,
    title = `Femmes 24.25%`,
    icon = faHashtag
}) => {
    const [valueToShow, setvalueToShow] = useState(0);

    useEffect(
        () => {
            if (!isNaN(value) && valueToShow != value) {
                setTimeout(() => {
                    setvalueToShow(valueToShow + 1);
                }, 6);
            }
        },
        [valueToShow]
    )

    return (
        <Grid
            xs={12}
            sm={6}
            md={4}
        >
            <Card
                sx={{
                    borderRadius: 20,
                    p: 1,
                    gap: 0,
                    background: bgcolor,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    overflow: "hidden",
                    transition: `all .2s`,
                    ":hover": {
                        transform: `translateY(-10px)`,
                        boxShadow: "0px 0px 10px rgb(45,45,45)"
                    }
                }}
            >
                <CardContent>
                    <Typography
                        fontSize={50}
                        textColor={"white"}
                        fontWeight={700}
                    >
                        {
                            isNaN(value) ? value : valueToShow
                        }
                    </Typography>
                    <Typography fontSize={20} textColor={"white"}>{title}</Typography>
                </CardContent>
                <FontAwesomeIcon
                    color='white'
                    opacity={0.4}
                    style={{
                        fontSize: "5rem",
                        position: "absolute",
                        marginLeft: "50%"
                    }}
                    icon={icon}
                />
            </Card>
        </Grid>
    )
}

export default StatistiqueItem