import { Button, Card, CardContent, Stack, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'
import { IMAGES } from '../../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const RepertoireAcheteursZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 25,
                border: 0,
                p: 0,
                alignItems: "flex-end",
                boxShadow: `0px 10px 15px grey`,
                transition: `all .2s`,
                overflow: "hidden",
                background: `linear-gradient(90deg, #9ebd13 0%, #008552 100%)`,
                ":hover": {
                    transform: `scale(1.01)`,
                    boxShadow: "0px 0px 10px rgb(45,45,45)"
                }
            }}
            component="section"
        >
            <CardContent>
                <CardMedia
                    component="img"
                    src={IMAGES.repertoireAcheteurs}
                    sx={{
                        width: "100%",
                        maxHeight: 150,
                    }}
                />
                <Stack
                    p={1}
                    color={"white"}
                    gap={2}
                >
                    <Typography component={"h1"} textColor={"white"} level='title-lg'>Répertoire des acheteurs</Typography>
                    <Typography textColor={"white"} level='body-md'>
                        Vous trouverez ci-dessous la liste des potentiels
                        acheteurs et cela par filière.
                        Vous pouvez aussi demander votre inscription à
                        cette liste si vous êtes un acheteur.
                    </Typography>
                </Stack>
            </CardContent>
            <CardContent
                sx={{
                    position: "absolute",
                }}
            >
                <Button
                    sx={{
                        borderRadius: `0 20px`
                    }}
                    endDecorator={
                        <FontAwesomeIcon icon={faArrowRight} />
                    }
                >
                    Plus d'information
                </Button>
            </CardContent>

        </Card>
    )
}

export default RepertoireAcheteursZone