import ble from "../../../assets/images/ble.jpeg"
import mais from "../../../assets/images/mais.jpeg"
import riz from "../../../assets/images/riz.jpeg"
import manioc from "../../../assets/images/manioc.jpeg"

export default [
    {
        name: "Bonnes pratiques communes",
        description: "Cette section contient des recommandations ou des bonnes pratiques qui sont transversales à toutes les filières.",
        img: ble
    },
    {
        name: "Bonnes pratiques - Maïs",
        description: "Comment produire le compost à base de la paille de riz en milieu aerien ?",
        img: mais,
    },
    {
        name: "Bonnes pratiques - Riz",
        description: "Comment produire le compost à base de la paille de riz en milieu aerien ?",
        img: riz,
    },
    {
        name: "Bonnes pratiques - Manioc",
        description: "Fiche technique élaborée par le CNRA portant sur la culture du manioc en Côte d’Ivoire",
        img: manioc,
    }
]