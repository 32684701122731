import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, FormControl, FormLabel, Option, Select, Stack, Table } from '@mui/joy'
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import data from "./data"
import { useRef, useState } from 'react'
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"

const TechniquesZone = () => {
    const [currentSecteurIndex, setcurrentSecteurIndex] = useState(0);
    const tableRef = useRef(null);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data[currentSecteurIndex].element);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Données");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(dataBlob, `Liste des financeurs du secteur ${data[currentSecteurIndex].secteur}.xlsx`);
    };

    return (
        <Stack>
            <Stack
                direction={"row"}
                alignItems={"flex-end"}
                gap={2}
            >
                <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Selectionnez un secteur</FormLabel>
                    <Select
                        placeholder="Selectionnez un secteur"
                        value={currentSecteurIndex}
                        onChange={(e, value) => setcurrentSecteurIndex(value || 0)}
                    >
                        {
                            data.map(({ secteur }, index) => (
                                <Option value={index} key={index}>{secteur}</Option>
                            ))
                        }
                    </Select>
                </FormControl>

                <Button
                    endDecorator={
                        <FontAwesomeIcon icon={faFileExcel} />
                    }
                    onClick={exportToExcel}
                >Exporter</Button>
            </Stack>

            <TableContainer sx={{ width: "100%" }}>
                <Table ref={tableRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Acteurs</TableCell>
                            <TableCell>Localisation</TableCell>
                            <TableCell>Contacts</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            data[currentSecteurIndex].element.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell>{value.acteurs}</TableCell>
                                    <TableCell>{value.localisation}</TableCell>
                                    <TableCell>{value.contact}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Stack>
    )
}

export default TechniquesZone