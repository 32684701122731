export default [
    {
        secteur: "Renforcement des capacites/Reglementations",
        element: [
            {
                acteurs: "Anader",
                localisation: "Belier de Yamoussokro",
                contact: "27 20 21 67 00"
            },
            {
                acteurs: "Aderiz",
                localisation: "Yamoussokro",
                contact: "27 20 22 80 00"
            },
            {
                acteurs: "Proril",
                localisation: "Yamoussokro",
                contact: "25 24 38 06 66"
            },
            {
                acteurs: "CI PME",
                localisation: "Belier de Yamoussokro",
                contact: "27 20 00 12 28"
            },
            {
                acteurs: "SOGEDI",
                localisation: "Belier de Yamoussokro",
                contact: "27 20 22 32 34"
            },
        ]
    }
]