import { Box, Container, Stack, Tab, tabClasses, TabList, TabPanel, Tabs, Typography } from "@mui/joy"
import { green, orange } from "@mui/material/colors"
import InfoText from '../../components/InfoText'
import FinancementZone from "./FinancementZone"
import TechniquesZone from "./TechniquesZone"
import AppearOnView from "../../components/AppearOnView"

const FinancementSoutien = () => {
    return (
        <Container>
            <Stack
                py={2}
                gap={2}
            >
                <Box>
                    <Typography
                        fontWeight={700}
                        textColor={green[900]}
                        level='body-lg'
                        component={"h1"}
                    >
                        Techniques & Technologies
                    </Typography>

                    <Box
                        bgcolor={orange[500]}
                        height={10}
                        width={70}
                        borderRadius={30}
                    />
                </Box>

                <InfoText
                    maxWidth={700}
                    text="Les structures de soutien pour le développement des chaînes de valeur des filières cibles du projet et les differentes sources de financement."
                />

                <Tabs
                    variant="outlined"
                    defaultValue={0}
                    sx={{
                        borderRadius: 'lg',
                        boxShadow: 'sm',
                        overflow: 'auto'
                    }}
                >
                    <TabList
                        // disableUnderline
                        tabFlex={1}
                        sx={{
                            [`& .${tabClasses.root}`]: {
                                fontSize: 'sm',
                                fontWeight: 'lg',
                                [`&[aria-selected="true"]`]: {
                                    color: green[900],
                                    bgcolor: 'background.surface',
                                },
                                [`&.${tabClasses.focusVisible}`]: {
                                    outlineOffset: '-4px',
                                },
                            },
                        }}
                    >
                        <Tab>Structures de financement</Tab>
                        <Tab>Structures techniques</Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <AppearOnView
                            element={<FinancementZone />}
                        />
                    </TabPanel>
                    <TabPanel value={1}>
                        <AppearOnView
                            element={<TechniquesZone />}
                        />
                    </TabPanel>
                </Tabs>
            </Stack>
        </Container>
    )
}

export default FinancementSoutien