import { faBuildingWheat, faFemale, faHandshakeAlt, faMoneyBill, faSackDollar, faTractor } from "@fortawesome/free-solid-svg-icons";

export default [
    {
        name: "Bénefices",
        value: 300,
        bgcolor: `linear-gradient(90deg, #1CB5E0 0%, #000851 100%)`,
        icon : faMoneyBill
    },
    {
        name: "Femmes 24.25%",
        value: 73,
        bgcolor: `linear-gradient(90deg, #d53369 0%, #daae51 100%)`,
        icon : faFemale
    },
    {
        name: "Agripreneurs",
        value: 223,
        bgcolor: `linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)`,
        icon : faTractor
    },
    {
        name: "Coût Projets",
        value: '+4M',
        bgcolor: `linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%)`,
        icon : faSackDollar
    },
    {
        name: "Coopératives",
        value: 36,
        bgcolor: `linear-gradient(90deg, #4b6cb7 0%, #182848 100%)`,
        icon : faHandshakeAlt
    },
    {
        name: "PME",
        value: 33,
        bgcolor: `linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%)`,
        icon : faBuildingWheat
    },
]