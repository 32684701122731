import { Grid } from '@mui/joy'
import ProductionZone from './ProductionZone'
import ConservationZone from './ConservationZone'
import TransformationZone from './TransformationZone'
import CommercialisationZone from './CommercialisationZone'

const RizZone = () => {
    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                xs={12}
                sm={6}
            >
                <ProductionZone />
            </Grid>

            <Grid
                xs={12}
                sm={6}
            >
                <CommercialisationZone />
            </Grid>

            <Grid
                xs={12}
                sm={6}
            >
                <ConservationZone />
            </Grid>

            <Grid
                xs={12}
                sm={6}
            >
                <TransformationZone />
            </Grid>

        </Grid>
    )
}

export default RizZone