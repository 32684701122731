import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, CardContent, Typography } from '@mui/joy'
import { green, orange } from '@mui/material/colors'
import SwipperZone from './SwipperZone'

const BonnesPratiqueZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardContent>
                <Typography fontWeight={700} textColor={green[900]} level='body-lg' component={"h1"}>Prix du marché</Typography>
                <Box
                    bgcolor={orange[500]}
                    height={10}
                    width={70}
                    borderRadius={30}
                />
            </CardContent>

            <CardContent>
                <Typography>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
                    Bonnes pratiques pour le développement des chaînes de valeur des filières cibles du projet et par filière.
                </Typography>
            </CardContent>

            <CardContent>
                <SwipperZone />
            </CardContent>

        </Card>
    )
}

export default BonnesPratiqueZone