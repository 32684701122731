import { faArrowRight, faDotCircle, faPeopleCarry } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/joy"
import CardTitleZone from "../../../components/CardTitleZone"
import InfoText from "../../../components/InfoText"
import { default as data } from "./data"

const EncadrementPromoteursZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid`,
                maxHeight: 400
            }}
            variant="soft"
            color="success"
            component="section"
        >
            <CardTitleZone
                title="Encadrement des promoteurs"
                icon={faPeopleCarry}
            />

            <InfoText
                text="Les Intrants & Equipements liés à la production et par filière."
            />

            <Stack
                gap={1}
                sx={{
                    overflowY: "scroll"
                }}
            >
                {
                    data.map((value, index) => (
                        <>
                            <Box>
                                <Typography
                                    level="h4"
                                    startDecorator={
                                        <FontAwesomeIcon icon={faDotCircle} />
                                    }
                                    color="success"
                                >
                                    {value.title}
                                </Typography>
                                <Typography ml={3}>{value.content}</Typography>
                            </Box>
                            {
                                index !== data.length - 1 && (<Divider />)
                            }
                        </>
                    ))
                }
            </Stack>

            <Button
                endDecorator={
                    <FontAwesomeIcon icon={faArrowRight} />
                }
                sx={{
                    alignSelf: "flex-end",
                    borderRadius: `15px 0`
                }}
            >Voir plus</Button>
        </Card>
    )
}

export default EncadrementPromoteursZone