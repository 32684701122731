import RizZone from "./RizZone";

export default [
    {
        name: "RIZ",
        component: <RizZone />
    },
    {
        name: "MAIS",
        component: <RizZone />
    },
    {
        name: "MANIOC",
        component: <RizZone />
    },
    {
        name: "PORC",
        component: <RizZone />
    },
    {
        name: "POISSON",
        component: <RizZone />
    },
    {
        name: "MARAICHAGE",
        component: <RizZone />
    },
]