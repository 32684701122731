import { faArrowRight, faFemale, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/joy'
import { green, orange, red } from '@mui/material/colors'
import { default as data } from "./data"
import StatistiqueItem from './StatistiqueItem'

const StatistiquesZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 20,
                border: `1px solid ${green[900]}`,
                alignSelf: "flex-start"
            }}
            variant="soft"
            component="section"
        >
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                mr={-2}
            >
                <Typography level='h3' component={"h1"}>Stactisques</Typography>
                <Button
                    endDecorator={
                        <FontAwesomeIcon icon={faArrowRight} />
                    }
                    sx={{
                        borderRadius: `10px 0 0 10px`,
                        bgcolor: green[900]
                    }}
                >Voir plus</Button>
            </Stack>

            <Grid
                container
                spacing={2}
            >
                {
                    data.map((value, index) => (
                        <StatistiqueItem
                            icon={value.icon}
                            bgcolor={value.bgcolor}
                            key={index}
                            title={value.name}
                            value={value.value as any}
                        />
                    ))
                }
            </Grid>
        </Card>
    )
}

export default StatistiquesZone