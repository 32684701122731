export default [
    {
        title: "Utilisation de Sacs de Stockage",
        elemetListe: [
            "offrant une protection contre I'hurnidité, les ravageurs puis stockage en magasin sur des palettes"
        ]
    },
    {
        title: "Sechage du Riz",
        elemetListe: [
            `pour réduire l'humidité du
            riz après la récolte avec des aires de
            séchage en plein alr ou des sécholrs solaires,
            des sécholrs mécanisés.`
        ]
    },
]