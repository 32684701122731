import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/css/parallax';
import 'swiper/css/pagination';
import { EffectCoverflow, Autoplay, Navigation, Thumbs, Parallax, Pagination } from 'swiper/modules';
import BonnesPratiqueItem from './BonnesPratiqueItem';
import {default as data} from "./data.js";

const SwipperZone = () => {
    return (
        <Swiper
            slidesPerView={1}
            modules={[EffectCoverflow, Autoplay, Navigation, Thumbs, Parallax, Pagination]}
            effect='coverflow'
            autoplay={{
                delay: 5000
            }}
            loop
            style={{
                width: "100%",
            }}
        >
            {
                data.map((value, index) => (
                    <SwiperSlide
                        key={index}
                    >
                        <BonnesPratiqueItem
                            name={value.name}
                            description={value.description}
                            image={value.img}
                        />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}

export default SwipperZone