import Router from "./Routes"
import "./assets/css/leaflet.css"
import "@fontsource/ubuntu"
import Header from "./components/Header"
import { Stack } from "@mui/joy"
import PlainNavbar from "./components/PlainNavbar"
import Footer from "./components/Footer"


const App = () => {
  return (
    <Stack>
      <Header />
      <PlainNavbar />
      <Router />
      <Footer />
    </Stack >
  )
}

export default App