import { Box, Container, Stack, Tab, tabClasses, TabList, TabPanel, Tabs, Typography } from "@mui/joy"
import { green, orange } from "@mui/material/colors"
import InfoText from "../../components/InfoText"
import data from "./data"
import { Link } from "react-router-dom"
import AppearOnView from "../../components/AppearOnView"

const TechniquesTechnologies = () => {
    return (
        <Container>
            <Stack
                py={2}
                gap={2}
            >
                <Box>
                    <Typography
                        fontWeight={700}
                        textColor={green[900]}
                        level='body-lg'
                        component={"h1"}
                    >
                        Techniques & Technologies
                    </Typography>

                    <Box
                        bgcolor={orange[500]}
                        height={10}
                        width={70}
                        borderRadius={30}
                    />
                </Box>

                <InfoText
                    maxWidth={700}
                    text="Les techniques et technologies de production, de transformation, de conservation, et de commercialisation adaptée aux filières cibles du projet."
                />

                <Tabs
                    variant="outlined"
                    defaultValue={0}
                    sx={{
                        borderRadius: 'lg',
                        boxShadow: 'sm',
                        overflow: 'auto'
                    }}
                >
                    <TabList
                        disableUnderline
                        tabFlex={1}
                        sx={{
                            [`& .${tabClasses.root}`]: {
                                fontSize: 'sm',
                                fontWeight: 'lg',
                                [`&[aria-selected="true"]`]: {
                                    color: green[900],
                                    bgcolor: 'background.surface',
                                },
                                [`&.${tabClasses.focusVisible}`]: {
                                    outlineOffset: '-4px',
                                },
                            },
                        }}
                    >
                        {
                            data.map((value, index) => (
                                <Tab
                                    disableIndicator
                                    variant="soft"
                                    sx={{ flexGrow: 1 }}
                                    key={index}
                                >
                                    {value.name}
                                </Tab>
                            ))
                        }
                    </TabList>
                    {
                        data.map((value, index) => (
                            <TabPanel value={index} key={index}>
                                <AppearOnView
                                    element={value?.component}
                                />
                            </TabPanel>
                        ))
                    }
                </Tabs>

                <Typography>
                    Voir la section <Link
                        target="_blank"
                        to={"https://data2paibelier.it.rehum.site/bonnes-pratiques/"}
                    >Bonnes pratiques</Link> pour consulter des fiches techniques détaillées produites par les structures aggrées.
                </Typography>
            </Stack>
        </Container>
    )
}

export default TechniquesTechnologies