import { Avatar, Button, Card, CardContent, Stack, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'
import { IMAGES } from '../../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const FluxCommercialisationZone = () => {
    return (
        <Card
            sx={{
                borderRadius: 25,
                border: 0,
                p: 0,
                alignItems: "flex-end",
                boxShadow: `0px 10px 15px grey`,
                transition: `all .2s`,
                overflow: "hidden",
                background: `linear-gradient(90deg, #9ebd13 0%, #008552 100%)`,
                ":hover": {
                    transform: `scale(1.01)`,
                    boxShadow: "0px 0px 10px rgb(45,45,45)"
                }
            }}
            component="section"
        >
            <CardContent>
                <CardMedia
                    component="img"
                    src={IMAGES.FluxCommercialisationZone}
                    sx={{
                        width: "100%",
                        maxHeight: 150,
                    }}
                />
                <Stack
                    p={1}
                    color={"white"}
                    gap={2}
                >
                    <Typography component={"h1"} textColor={"white"} level='title-lg'>Flux de commercialisation</Typography>
                    <Typography textColor={"white"} level='body-md'>
                        Les flux principaux sont orientés majoritairement du Nord
                        vers le Sud du pays , pour le Riz. Relativement à la filière
                        piscicole, plus de 70% des pisciculteurs écoulent leurs productions
                        bord- champ et environ 14% réalisent leurs ventes sur les marchés,
                        et respectivement 8% et 7% livrent directement
                        leurs produits aux grossistes et aux ménages.
                    </Typography>
                </Stack>
            </CardContent>
            <CardContent
                sx={{
                    position: "absolute",
                }}
            >
                <Button
                    sx={{
                        borderRadius: `0 20px`
                    }}
                    endDecorator={
                        <FontAwesomeIcon icon={faArrowRight} />
                    }
                >
                    Plus d'information
                </Button>
            </CardContent>

        </Card>
    )
}

export default FluxCommercialisationZone